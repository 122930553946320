import { useState } from "react";

function MyArrays() {
  const [cars, setCars] = useState([]);
  const [carYear, setYear] = useState(new Date().getFullYear());
  const [carMake, setCarMake] = useState("");
  const [carModel, setCarMeodel] = useState("");

  function handleAddCar() {
    const newCar = { year: carYear, make: carMake, model: carModel };
    setCars((c) => [...c, newCar]);
    setYear(new Date().getFullYear());
    setCarMake("");
    setCarMeodel("");
  }

  function handleRemoveCar(index) {
    setCars(cars.filter((_, i) => i !== index));
  }

  function handleYear(e) {
    setYear(e.target.value);
  }

  function handleMake(e) {
    setCarMake(e.target.value);
  }

  function handleModel(e) {
    setCarMeodel(e.target.value);
  }

  return (
    <div>
      <h2>list of cars</h2>
      <ul>
        {cars.map((car, index) => (
          <li key={index} onClick={() => handleRemoveCar(index)}>
            {car.year} {car.make} {car.model}
          </li>
        ))}
      </ul>
      <input type="number" value={carYear} onChange={handleYear} />
      <br />
      <input
        type="text"
        value={carMake}
        onChange={handleMake}
        placeholder="enter car compnay"
      />
      <br />
      <input
        type="text"
        value={carModel}
        onChange={handleModel}
        placeholder="enter car model"
      />
      <button onClick={handleAddCar}>Add Car</button>
    </div>
  );
}

export default MyArrays;
