import Proptypes from "prop-types";
function Chefgreeting(props) {
  const greeting = <h1 className="greeting">Welcome {props.name}</h1>;

  const loginPrompt = <h1 className="login-prompt">Please login</h1>;

  return props.isLoggedIn ? greeting : loginPrompt;
}
//chcking proptype
Chefgreeting.propTypes = {
  isLoggedIn: Proptypes.bool,
  name: Proptypes.string,
};
//checking default props
Chefgreeting.defaultProps = {
  isLoggedIn: false,
  name: "guest",
};

export default Chefgreeting;
