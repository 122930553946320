import React, { useState } from "react";

function Mycomponent() {
  const [name, setName] = useState("guest");
  const [age, setAge] = useState(0);
  const [isEmployed, setIsEmployed] = useState(false);

  const updateName = () => {
    setName("John Doe");
  };
  const updateAge = () => {
    setAge(age + 1);
  };
  const updateIsEmployed = () => {
    setIsEmployed(!isEmployed);
  };

  return (
    <div>
      <p>My name is {name}</p>
      <button onClick={updateName}>Set Name</button>

      <p>My age is {age}</p>
      <button onClick={updateAge}>Set Age</button>

      <p>My employment is {isEmployed ? "true" : "false"}</p>
      <button onClick={updateIsEmployed}>Set Employment</button>
    </div>
  );
}
export default Mycomponent;
