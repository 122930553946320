import Header from "./Header.jsx";
import Footer from "./Footer.jsx";
import Food from "./Food.jsx";
import Card from "./Card.jsx";
import Button from "./Button/Button.jsx";
import Chefgreeting from "./Chefgreeting.jsx";
import List from "./List.jsx";
import Mycomponent from "./Mycomponent.jsx";
import Counter from "./counter.jsx";
import OnEvents from "./onEvents.jsx";
import Myupdate from "./updating.jsx";
import MyArrays from "./myArrays.jsx";
import EmployeeForm from "./Action.jsx";

function App() {
  return (
    <>
      <Header />
      <Chefgreeting isLoggedIn={true} name="John Doe" />
      <List />
      <Food />
      <Mycomponent />
      <Counter />
      <OnEvents />
      <Card />
      <Myupdate />
      <Button />
      <EmployeeForm />
      <MyArrays />
      <Footer />
    </>
  );
}
export default App;

//props: read-only properties that are passed to a component.
//A parent component cand send props to a child component.
//A child component can also receive props from a parent component.
//<Component key=value />
//state: a component can have a state that can be updated by the parent component.
//A child component can also have a state that can be updated by the parent component.

//life cycle: a component can have a life cycle that is called when the component is created, updated, or destroyed.
//A child component can also have a life cycle that is called when the component is created, updated, or destroyed.
