import { useState } from "react";
import styles from "./Button.module.css";
import axios from "axios";

function Button() {
  const [data, setData] = useState();
  //const handleClick = (e) => (e.target.textContent = "Clicked");
  const getQuote = () => {
    axios
      .get("https://fahimapi.bene.co.in/api/employees/")
      .then((res) => {
        setData(res.data);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {/* <button className={styles.btn} onClick={(e) => handleClick(e)}>
        Click Me
      </button> */}
      <button className={styles.btn} onClick={getQuote}>
        Click Me
      </button>
      <table style={{ width: "25%", textAlign: "left" }}>
        <thead>
          <tr>
            <th>Id</th>
            <th>Employee Id</th>
            <th>Name</th>
            <th>Nationality</th>
            <th>salary</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              {console.log(item)}
              <td>{item.Id}</td>
              <td>{item.EmployeeCode}</td>
              <td>{item.Name}</td>
              <td>{item.Nationality}</td>
              <td>{item.Salary}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default Button;
