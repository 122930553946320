import { useState } from "react";

function Food() {
  const [food, setFood] = useState(["Burger", "pizza", "salad"]);

  function addingFood() {
    const newFood = document.getElementById("addFood").value;
    document.getElementById("addFood").value = "";
    setFood((f) => [...f, newFood]);
  }

  function removingFood(index) {
    setFood(food.filter((_, i) => i !== index));
  }

  return (
    <div>
      <h2>Food list</h2>
      <ul>
        {food.map((food, index) => (
          <li key={index} onClick={() => removingFood(index)}>
            {food}
          </li>
        ))}
      </ul>
      <input type="text" id="addFood" placeholder="Add food" />
      <button onClick={addingFood}>Add Food</button>
      <br />
    </div>
  );
}
export default Food;
