function List(props) {
  const Food = [
    { id: 1, name: "Pizza", calories: 125 },
    { id: 2, name: "Burger", calories: 225 },
    { id: 3, name: "Pasta", calories: 187 },
    { id: 4, name: "Mango IceCream", calories: 221 },
    { id: 5, name: "Sandwich", calories: 200 },
    { id: 6, name: "izzy", calories: 125 },
  ];

  //Food.sort((a, b) => a.name.localeCompare(b.name)); //Alphabetical order
  //Food.sort((a, b) => b.name.localeCompare(a.name)); //reverse Alphabetical order
  //Food.sort((a, b) => a.calories - b.calories); //calories order
  //Food.sort((a, b) => b.calories - a.calories); //reverse calories order
  /*
  const HighCal = Food.filter((Food) => Food.calories >= 200);
  const LowCal = Food.filter((Food) => Food.calories <= 200);

  const FoodList = HighCal.map((HighCal) => (
    <li key={HighCal.id}>
      {HighCal.name}:&nbsp;
      <b>{HighCal.calories}</b>
    </li>
  ));
*/
  const FoodList = Food.map((Food) => (
    <li key={Food.id}>
      {Food.name}:&nbsp;
      <b>{Food.calories}</b>
    </li>
  ));
  return <ul>{FoodList}</ul>;
}
export default List;
