import BurgerPic from "./5787016.png";

const data = [
  { id: 1, image: BurgerPic, title: "Burger", text: "This is a Burger" },
  { id: 2, image: "./5787016.png", title: "Burger", text: "This is a Burger" },
  { id: 3, image: "./5787016.png", title: "Burger", text: "This is a Burger" },
  { id: 4, image: "./5787016.png", title: "Burger", text: "This is a Burger" },
  { id: 5, image: "./5787016.png", title: "Burger", text: "This is a Burger" },
];
function Card() {
  return (
    <>
      {data.map((data) => (
        <div className="card">
          <img className="card-image" src={data.image} alt={data.alt}></img>
          <h2 className="card-title">{data.title}</h2>
          <p className="card-text">{data.text}</p>
        </div>
      ))}
    </>
  );
}

export default Card;
