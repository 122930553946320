import { useState } from "react";

//update a state of an object
function Myupdate() {
  const [car, setCar] = useState({
    name: "Porsche",
    price: 10000000,
    color: "red",
  });
  function handleName(e) {
    setCar((c) => ({ ...c, name: e.target.value }));
  }
  function handlePrice(e) {
    setCar((c) => ({ ...c, price: e.target.value }));
  }
  function handlecolor(e) {
    setCar((c) => ({ ...c, color: e.target.value }));
  }

  return (
    <div>
      <p>
        Youre Fav car is {car.name} with the price of {car.price} and its color
        is {car.color}
      </p>
      <input type="text" onChange={handleName} value={car.name} />
      <br />
      <input type="number" onChange={handlePrice} value={car.price} />
      <br />
      <input type="text" onChange={handlecolor} value={car.color} />
    </div>
  );
}
export default Myupdate;
