import { useState } from "react";
import axios from "axios";

const EmployeeForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    employee_code: "",
    Nationality: "",
    salary: "",
  });
  const [id, setId] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://fahimapi.bene.co.in/api/employees/", formData)
      .then((response) => {
        console.log("Done", response.data);
        alert("Data sent successfully!");
      })
      .catch((error) => {
        console.error("There was an error ", error);
        alert("There was an error!");
      });
  };
  const handleDelete = (event) => {
    event.preventDefault();
    axios
      .delete(`https://fahimapi.bene.co.in/api/employees/${id}`)
      .then((response) => {
        console.log("Data deleted successfully:", response.data);
        alert("Data deleted successfully!");
      })
      .catch((error) => {
        console.error("There was an error ", error);
        alert("There was an error");
      });
  };
  const handleUpdate = (event) => {
    event.preventDefault();
    axios
      .put(`https://fahimapi.bene.co.in/api/employees/${id}`, formData)
      .then((response) => {
        console.log("Data updated :", response.data);
        alert("Data updated");
      })
      .catch((error) => {
        console.error("There was an error ", error);
        alert("There was an error ");
      });
  };

  return (
    <div>
      <h1>Submit Data</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </label>
        <br />

        <br />
        <label>
          Employee ID:
          <input
            type="text"
            name="employee_code"
            value={formData.employee_code}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <label>
          Nationality:
          <input
            type="text"
            name="Nationality"
            value={formData.Nationality}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <label>
          Salary:
          <input
            type="number"
            name="salary"
            value={formData.salary}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <button type="submit">Submit</button>
      </form>
      <h2>Update/Delete Data</h2>
      <form>
        <label>
          ID (for Update/Delete):
          <input
            type="text"
            value={id}
            onChange={(e) => setId(e.target.value)}
            required
          />
        </label>

        <br />
        <button type="button" onClick={handleUpdate}>
          Update
        </button>
        <button type="button" onClick={handleDelete}>
          Delete
        </button>
      </form>
    </div>
  );
};

export default EmployeeForm;
