import { useState } from "react";

function OnEvents() {
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState();
  const [comment, setComment] = useState();
  const [payment, setPayment] = useState();
  const [shipping, setShipping] = useState();

  function handleChange(e) {
    setName(e.target.value);
  }

  function handleQuantityChange(e) {
    setQuantity(e.target.value);
  }

  function handleCommentChange(e) {
    setComment(e.target.value);
  }

  function handlepaymentChange(e) {
    setPayment(e.target.value);
  }

  function handleShipping(e) {
    setShipping(e.target.value);
  }

  return (
    <div>
      <input value={name} onChange={handleChange} />
      <p>Hello {name}</p>

      <input value={quantity} onChange={handleQuantityChange} type="number" />
      <p>You have {quantity} apples</p>

      <textarea
        value={comment}
        onChange={handleCommentChange}
        placeholder="Add a comment"
      />
      <p>Your comment: {comment}</p>

      <select value={payment} onChange={handlepaymentChange}>
        <option value="">Select a payment plan</option>
        <option value="Visa">Visa</option>
        <option value="Mastercard">Mastercard</option>
        <option value="American Express">American Express</option>
      </select>
      <p>Your payment plan: {payment}</p>

      <label>
        <input
          type="radio"
          value="Pickup"
          checked={shipping === "Pickup"}
          onChange={handleShipping}
        />
        PickUp
      </label>
      <br></br>
      <label>
        <input
          type="radio"
          value="Delivery"
          checked={shipping === "Delivery"}
          onChange={handleShipping}
        />
        Delivery
      </label>
    </div>
  );
}
export default OnEvents;
