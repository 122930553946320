function Header() {
  return (
    <header>
      <h1>My React App</h1>
      <nav>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/">About</a>
          </li>
          <li>
            <a href="/">Contact</a>
          </li>
          <li>
            <a href="/">Work with us</a>
          </li>
        </ul>
      </nav>
      <hr></hr>
    </header>
  );
}
export default Header;
